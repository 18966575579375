import React, {useState} from 'react'
import { AuthContext } from '../firebase';

const AuthProvider = props => {
  // const [authUser, setAuthUser] = useState({user: ""})
  const [authUser, setAuthUser] = useState(localStorage.getItem('user') ? {user: JSON.parse(localStorage.getItem('user'))} : {user: ""})
  return (
    <AuthContext.Provider value={{authUser, setAuthUser}}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

